import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Bowser from 'bowser';
import uuidv4 from 'uuid/v4';

import { BaseService } from './base.service';
import { ConfigService } from './config.service';
import { Model } from '../models';

@Injectable()
export class DeviceService extends BaseService {
  private readonly deviceModel = Model.create<any>(undefined);

  readonly device$ = this.deviceModel.data$;

  constructor(
    @Inject(forwardRef(() => HttpClient)) http: HttpClient,
    configService: ConfigService
  ) {
    super(http, configService);
    //this.registerDevice();
  }

  get device() {
    return this.deviceModel.get();
  }

  registerDevice() {
    const uuid = uuidv4();
    const ua = window.navigator.userAgent;
    const { os } = Bowser.parse(ua);
    const url = `${this.configService.domain}/api/devices/v1/register_device?hardware_id=${uuid}&operating_system=${os.name}`;
    this.post({}, url, { headers: { Authorization: 'appconfigtool:3F.42N.iJVEy9A3bP' } }, true).subscribe(value => {
      this.deviceModel.set(value.device);
    });
  }
}
