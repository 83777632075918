import { Component } from '@angular/core';

import { ReactiveComponent } from '../../reactive-component';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent extends ReactiveComponent {

  reports: number = 0;
  orders: number = 0;
  pos_tickets: number = 0;

  *onSubscribe() {

  }

  updateMetrics(event:any) {
    this.reports = event.reports;
    this.orders = event.orders;
    this.pos_tickets = event.pos_tickets;
  }
}
