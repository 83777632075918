import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActionCableService } from 'angular2-actioncable';

import { ConfigService, WebSocketsService, DeviceService } from './services';
import { AppHeaderComponent, AppFooterComponent, EventsComponent } from './components';

const DECLARED_EXPORTS = [
    AppHeaderComponent, AppFooterComponent, EventsComponent
];

const RELAYED_EXPORTS = [
    CommonModule, HttpClientModule, FormsModule
];

const ENTRY_COMPONENTS = [

];

const PROVIDERS = [
    ActionCableService, ConfigService, WebSocketsService, DeviceService
];

@NgModule({
    declarations: [
        ...DECLARED_EXPORTS
    ],
    providers: [
        ...PROVIDERS
    ],
    imports: [
        RouterModule,
        ...RELAYED_EXPORTS
    ],
    exports: [
        ...RELAYED_EXPORTS,
        ...DECLARED_EXPORTS
    ],
    entryComponents: [
        ...ENTRY_COMPONENTS
    ]
})
export class BaseModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: BaseModule,
            providers: [
                ...PROVIDERS
            ]
        };
    }
}
