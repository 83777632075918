<div class="container content no-padding">
    <div class="row item no-gutter no-padding" *ngFor="let item of events">
        <div class="col-11 pt-3">
            <div class="row">
                <div class="col-12">
                    <p class="event-datetime">{{ item.datetime | date:'shortTime' }} | {{ item.datetime | date:'mediumDate' }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="event-message">{{ item.message }}</p>
                </div>
            </div>
        </div>
        <div class="col-1 no-padding">
            <ng-container [ngSwitch]="item.message_type">
                <ng-container *ngSwitchCase="'snack_delivery_worksheet_report' || 'snack_report'">
                    <div class="event-icon snack">
                        <img src="assets/image/snack.svg" class="img-fluid" />
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'order'">
                    <div class="event-icon order">
                        <img src="assets/image/orders.svg" class="img-fluid" />
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'pos_ticket'">
                    <div class="event-icon pos">
                        <img src="assets/image/tickets.svg" class="img-fluid" />
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'production_report'">
                    <div class="event-icon production">
                        <img src="assets/image/production.svg" class="img-fluid" />
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'temperature_log'">
                    <div class="event-icon temperature">
                        <img src="assets/image/temp.svg" class="img-fluid" />
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div class="event-icon default">
                        <img src="assets/image/reports.svg" class="img-fluid" />
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>