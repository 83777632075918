import { Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ConfigService } from './config.service';

export abstract class BaseService {
    private busySubject = new Subject<boolean>();

    readonly busy$ = this.busySubject;

    constructor(
        @Inject(forwardRef(() => HttpClient))
        protected http: HttpClient,
        protected configService: ConfigService
    ) { }

    protected get<T>(url: string): Observable<T | any> {
        this.busySubject.next(true);
        return this.http
            .get<T>(`${this.configService.host}${url}`)
            .pipe(
                switchMap(this.handleResult()),
                catchError(this.handleError<T>(`GET: ${url}`))
            );
    }

    protected request<T>(url: string): Observable<T | any> {
        this.busySubject.next(true);
        return this.http
            .request<T>('POST', `${this.configService.host}${url}`, { body: {} })
            .pipe(
                switchMap(this.handleResult()),
                catchError(this.handleError<T>(`GET: ${url}`))
            );
    }

    protected post<T>(data: any, url: string, options, isFullUrl = false) {
        this.busySubject.next(true);
        return this.http
            .post<T>(`${isFullUrl ? '' : this.configService.host}${url}`, JSON.stringify(data), options)
            .pipe(
                switchMap(this.handleResult()),
                catchError(this.handleError(`POST: ${url}`))
            );
    }

    protected put<S, T>(url: string, dto: S) {
        this.busySubject.next(true);
        return this.http
            .put<T>(`${this.configService.host}${url}`, dto)
            .pipe(
                switchMap(this.handleResult()),
                catchError(this.handleError<T>(`PUT: ${url}`)),
            );
    }

    private handleResult() {
        return (response: any) => {
            this.busySubject.next(false);
            if (response) {
                return of(response);
            }
            return throwError(response);
        };
    }

    protected handleError<T>(operation: string) {
        return (response: HttpErrorResponse): Observable<T> => {
            const errors = response.error && response.error.messages && response.error.messages.errors || [response.message];
            const message = errors.join('\n');
            console.error(`${operation} failed: ${message}`);

            // Let the app keep running by returning an empty result.
            return throwError(message);
        };
    }
}
