<header>
    <div class="container">
        <div class="row header align-items-center">
            <div class="col-6">
                <img src="assets/image/logo.png" class="img-fluid">
            </div>
            <div class="col-6">
                <p class="no-padding no-margin">Delivering smiles and satisfaction.</p>
            </div>
        </div>
    </div>
</header>