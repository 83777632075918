import { Injectable } from '@angular/core';
import { Dictionary, get } from 'lodash';
import { ActionCableService, Channel, Cable } from 'angular2-actioncable';
import { Subject, Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { DeviceService } from './device.service';

type WsSubscription = {
    cable: Cable;
    obs: Subject<any>;
};

@Injectable()
export class WebSocketsService {
    wsHost: string;
    wsSubscriptions: Dictionary<WsSubscription> = {};

    constructor(
        private cableService: ActionCableService,
        private configService: ConfigService) {
    }

    /** Initiate the websocket cable */
    init(authCode: string) {
        const { hostname } = new URL(this.configService.host);
        this.wsHost = `wss://${hostname}/cable?metrics=DC7UC88XZNZ52VEmR3uYZMmk8XJG:${authCode}`;
        //console.log('Initiating ws with host', this.wsHost);
    }

    /** Subscribe to websocket channel */
    subscribeTo<T>(channelName: string, params: any = {}, callback: (value: T) => void = null) {
        //console.log('Subscribing to ws', { channelName, params });

        // Unsubscribe from any previous subscriptions
        this.unsubscribe(channelName);

        // Create cable
        const cable: Cable = this.cableService.cable(this.wsHost);
        const channel: Channel = cable.channel(channelName, { ...params });

        // Subscribe to websocket
        const obs = new Subject<T>();
        channel.received().subscribe((data: T) => obs.next(data));

        // setup local variable
        this.wsSubscriptions[channelName] = { obs, cable };

        // Handle broadcasts
        if (callback) {
            obs.subscribe(callback);
        } else {
            return obs.asObservable();
        }
    }

    /** Unsubscribe from current channel */
    unsubscribe(channelName: string) {
        // Get the subscription and the cable
        const { cable, obs } = get(this.wsSubscriptions, channelName, {} as WsSubscription);
        // Disconnect if cable present
        if (cable) {
            //console.log('Unsubscribing from ws channel', channelName);
            cable.disconnect();
            delete this.wsSubscriptions[channelName];
            obs.complete();
        }
    }

    getSubscriptionChannel(channel: string, params: any = {}): Observable<any> {
        const { obs } = get(this.wsSubscriptions, channel, {} as WsSubscription);
        return obs.asObservable();
    }
}
