import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { filter, map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { WebSocketsService } from './base/services';
import { WEBSOCKET_CHANNELS } from './base/models';
import { AppFooterComponent } from './base/components';
import { fromEvent } from 'rxjs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

const APP_KEY = '8kKb6RMRhZMMxXz';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class AppComponent implements OnInit, AfterViewInit {
  isInitWs = false;
  @ViewChild('codeInput', { read: ElementRef }) private codeInput: ElementRef;
  @ViewChild('footer') footer: AppFooterComponent;

  authCode: string;
  codeParam = '';
  errorMsg: string = '';
  needsAuthInput = true;

  constructor(private ws: WebSocketsService,
    private location: Location) {
  }

  ngOnInit(): void {
    const code = this.location.path().replace('/', '').trim();
    this.codeParam = code;
    if (code !== '') {
      this.initFromUrl(code);
    }
  }

  ngAfterViewInit(): void {
    if(!this.authCode) {
      this.initFromInput();
    }
  }

  private initFromInput() {
    fromEvent(this.codeInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        filter(res => res.length === APP_KEY.length),
        debounceTime(100),
        distinctUntilChanged(),
        tap(() => this.errorMsg = ''),
        tap((code: string) => {
          this.initActivityFeed(code);
        })
      )
      .subscribe();
  }

  private initFromUrl(code: string) {
    this.initActivityFeed(code);
  }

  private initActivityFeed(code: string) {
    if(code === APP_KEY){
      this.authCode = code;
      this.completeSetup();    
    } else {
      this.errorMsg = 'Invalid Code';
    }
  }

  private completeSetup() {
    this.ws.init(this.authCode);
    this.ws.subscribeTo(WEBSOCKET_CHANNELS.METRICS, { metrics: 'DC7UC88XZNZ52VEmR3uYZMmk8XJG:'+this.authCode });
    this.isInitWs = true;
  }

  updateUsage(event){
    this.footer.updateMetrics(event);
  }
}
