import { Component, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';

import { ReactiveComponent } from '../../reactive-component';
import { WebSocketsService } from '../../services';
import { WEBSOCKET_CHANNELS } from '../../models';

@Component({
  selector: 'ms-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends ReactiveComponent {
  events: any[] = [];

  @Output() updateUsage: EventEmitter<number> = new EventEmitter<number>();

  constructor(private ws: WebSocketsService,
    private cdr: ChangeDetectorRef) {
    super();
  }

  *onSubscribe() {
    yield this.ws.getSubscriptionChannel(WEBSOCKET_CHANNELS.METRICS).subscribe(event => {
      if(event.event_type === 'message') {

        //Remove first element in array if 20 items or more
        const len = this.events.length;
        if(len >= 15) {
          this.events.splice(len-1,1);
        }

        event.datetime = new Date();
        this.events.unshift(event);
        this.cdr.detectChanges();
      } else {
        this.updateUsage.emit(event);
      }
    });
  }
}
