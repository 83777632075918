import { OnDestroy, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { set } from 'lodash';

/**
 * Inherit this component base class for all components having reactive subscriptions during their lifetime
 */
export abstract class ReactiveComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    public ngOnInit(): void {
        const subs = this.onSubscribe();
        for (const sub of subs) {
            this.subscription.add(sub);
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Called after ngOnInit, any Subscriptions returned by this method will be unsubscribed in ngOnDestroy.
     * Use the generator syntax to setup your subscriptions, eg:
     * ```typescript
     * *onSubscribe() {
     *  yield this.someObservable.subscribe(...);
     * }
     * ```
     */
    abstract onSubscribe(): Iterable<Subscription>;

    protected addSubscription(sub: Subscription) {
        this.subscription.add(sub);
    }

    untilDestroyed<T>(observable: Observable<T>, next?: ((value: T) => any) | string) {
        this.addSubscription(observable.subscribe(value => {
            if (typeof next === 'string') {
                set(this, next, value);
            } else {
                // tslint:disable-next-line:ban-types
                (next as Function)(value);
            }
        }));
    }
}
