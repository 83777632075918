<footer class="py-3">
    <div class="container d-flex flex-column h-100">
        <div class="row">
            <div class="col-4 reports">
                <div class="icon-wrapper">
                    <img src="assets/image/reports.svg" class="img-fluid">
                </div>
                <div>
                    <p class="count">{{ reports }}</p>
                    <p class="count-desc">Reports Run Today</p>
                </div>
            </div>
            <div class="col-4 orders-placed">
                <div class="icon-wrapper">
                    <img src="assets/image/orders.svg" class="img-fluid">
                </div>
                <div>
                    <p class="count">{{ orders }}</p>
                    <p class="count-desc">Orders Placed Today</p>
                </div>
            </div>
            <div class="col-4 smiles-delivered">
                <div class="icon-wrapper">
                    <img src="assets/image/tickets.svg" class="img-fluid">
                </div>
                <div>
                    <p class="count">{{ pos_tickets }}</p>
                    <p class="count-desc">Point of Sale Tickets</p>
                </div>
            </div>
        </div>
    </div>
</footer>