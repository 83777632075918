import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ENVIRONMENT, TENANT_TYPE } from '../models';

const MS_API_VERSION = 1;
const MS_API_VERSION_PATH = `api/pos/v${MS_API_VERSION}/`;
const MS_API_URL = (domain: string) => {
  return `${domain}/${MS_API_VERSION_PATH}`;
};

/** API URL Generator */
export const MS_API_DOMAIN = (subdomain: string, domainSuffix: string = '') => {
  return `https://${subdomain}.touch.mealsuite.com`;
};

@Injectable()
export class ConfigService {
  host = '';
  domain = '';

  constructor() {
    this.configHost();
  }

  configHost() {
    const subdomain = TENANT_TYPE.SYNERGY;
    let hostSuffix = '';
    if (environment.SENTRY_ENV !== ENVIRONMENT.PRODUCTION) {
      hostSuffix = environment.SENTRY_ENV === ENVIRONMENT.BETA ? ENVIRONMENT.SANDBOX : ENVIRONMENT.DEV;
    }

    this.host = this.apiURLFor(subdomain, hostSuffix);
  }

  /** Get the api urls for the given provider */
  private apiURLFor(subdomain: string, domainSuffix: string): string {
    const domain = subdomain === TENANT_TYPE.MYUSUITE ? 'mus20' : subdomain;
    const isStaging = Boolean(domainSuffix);
    const domainSuffixTemp = isStaging ? `-${domainSuffix}` : '';
    this.domain = MS_API_DOMAIN(subdomain, domainSuffixTemp);
    const url = (isStaging && environment.STAGING_URL) || MS_API_URL(this.domain);
    return url;
  }
}
