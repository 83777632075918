<div class="d-flex flex-column h-100 signin" *ngIf="!authCode">
    <div class="container">
        <div class="row">
            <div class="col-12 wrapper">
                <div>
                    <img class="mb-4" src="assets/image/logo-white-bg.png" alt="">
                    <input #codeInput class="form-control" placeholder="Authentication Code">
                    <div class="alert alert-danger mt-4" role="alert" *ngIf="errorMsg != ''">
                        {{ errorMsg }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex flex-column h-100" *ngIf="authCode">
    <app-header></app-header>

    <ms-events *ngIf="isInitWs" (updateUsage)="updateUsage($event)"></ms-events>

    <app-footer class="footer mt-auto" #footer></app-footer>
</div>