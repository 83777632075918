export const TENANT_TYPE = {
    SYNERGY: 'synergy',
    THREE_SQUARES: 'threesquares',
    REVERA: 'revera',
    MYUSUITE: 'myusuite'
};

export const ENVIRONMENT = {
    PRODUCTION: 'production',
    BETA: 'beta',
    SANDBOX: 'sandbox',
    DEV: 'dev',
    STAGING: 'staging'
};

export const WEBSOCKET_CHANNELS = {
    METRICS: 'MetricsChannel'
};
